<!-- 个人中心 -->
<template>
  <div class="personal-page">
    <img src="@/assets/logo.png" class="wechat-logo" />
    <p class="tips">航都院OA</p>
    <p class="success-tips" v-if="1 == loginSucess">微信登录成功</p>
  </div>
</template>

<script>
import { loginWithWechat } from "@/api/account";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      loginSucess: 0,
    };
  },
  name: "wechatloginWithcode",
  components: {},
  computed: {},
  mounted() {
    let code = this.$route.query.code;
    // 微信授权后
    if (code) {
      this.confirmLogin(code);
      return;
    }
  },

  methods: {
    confirmLogin(code) {
      const postData = {
        code: code,
        unionCode: this.$route.params.id,
      };
      loginWithWechat(postData).then((res) => {
        this.loginSucess = 1;
        localStorage.setItem("isLogin", "isLogin", {
          path: `/wechatlogin/${this.$route.params.id}`,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  .wechat-logo {
    margin-top: 1rem;
    width: 4rem;
    height: 4rem;
  }
  .tips {
    color: #333;
    font-size: 0.32rem;
    font-weight: bold;
    margin-top: 0.2rem;
    line-height: 1.5;
  }
  .button {
    margin-top: 2rem;
    width: 6.9rem;
    height: 0.8rem;
    margin-bottom: 1rem;
    background: #0094ee;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.3rem;
  }
  .success-tips {
    color: #999;
    font-size: 0.26rem;
    margin-top: 1rem;
    max-width: 6.9rem;
    line-height: 1.5;
  }
}
</style>
